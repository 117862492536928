import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Login from './pages/Login';
import Admin from './pages/Admin';
import Home from './pages/Home';
import Header from './components/Header';
import { onAuthStateChanged } from 'firebase/auth';
import Preloader from './components/Preloader';
import NotFoundPage from './pages/NotFoundPage';
import RestrictedPage from './pages/RestrictedPage';
import ProfilePage from './pages/ProfilePage';
import UpdateProfilePage from './pages/UpdateProfilePage';
import { auth, firestore } from './firebaseConfig';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import Menu from './components/Menu';
import AnimatedCursor from 'react-animated-cursor';
import Download from './pages/Download';
// import Services from './pages/Services';
import { useAuth } from './context/AuthContext';
import Profile from './pages/Profile';

const notLoggedInLinks = [
  {
    title: "Get Started",
    to: "/"
  },
  {
    title: "Sign In",
    isLink: true,
    to: "/login",
  },
  {
    title: "LinkTree",
    isLink: true,
    to: "/linktree",
  },
  {
    title: "Download",
    isLink: true,
    to: "/download",
    isBig: true,
  },
  // {
  //   title: "LOGIN",
  //   to: "/login",
  //   isBig: true,
  // },
  // {
  //   title: "SIGNUP NOW",
  //   to: "/signup",
  //   isBig: true,
  // },
]

const loggedInLinks = [
  {
    title: "My Profile",
    to: "/profile"
  },
  {
    title: "Update Profile",
    to: "/update-profile"
  },
  {
    title: "LinkTree",
    to: "https://linktr.ee/mycrushdate",
    isLink:true,
  },
  {
    title: "Download",
    to: "/download",
    isLink:true,
  },
]

function App() {
  const [ appState, setAppState ] = useState({
    isLoggedIn: false,
    isLoading: true,
    darkTheme: false,
    links: [],
    isAdmin: false,
    isPremium: false,
    services: [
      // {
      //   provider: {
      //     uid: "abcd1234",
      //     userName: "thisguy",
      //     displayName: "Isaac Adebayo",
      //     profilePhoto: "",
      //   },
      //   type: "massage",
      //   location: "anywhere",
      //   charge: "20",
      //   rate: "hour"
      // },
      // {
      //   provider: {
      //     uid: "abcd1234",
      //     userName: "thisguy",
      //     displayName: "Top Boy",
      //     profilePhoto: "",
      //   },
      //   type: "escort",
      //   location: "anywhere",
      //   charge: "200",
      //   rate: "hour"
      // },
    ]
  })

  const { currentUser } = useAuth();

  const [showMenu, setShowMenu] = useState(false)

  const [ loadedUser, setLoadedUser ] = useState({
    username: "",
    uid: "",
    displayName: "",
    email: "",
    profileImageUrl: "",
    pronouns: "",
    dateOfBirth: "",
    sex: "",
    isProvider: false,
    myServices: [],
    friends: [],
    messages: []
  })

  const location = useLocation()

  const currentPath = location?.pathname

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAppState((prev)=>{
          return ({
            ...prev,
            isLoggedIn: true,
            isLoading: false,
          })
        })
      } else {
        setAppState((prev)=>{
          return ({
            ...prev,
            isLoggedIn: false,
            isLoading: false,
          })
        })
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(()=>{
    if(appState.isLoggedIn){
      setAppState((prev)=>{
        return ({
          ...prev,
          links: [
            ...loggedInLinks
          ]
        })
      })
    } else{
      setAppState((prev)=>{
        return ({
          ...prev,
          links: [
            ...notLoggedInLinks
          ]
        })
      })
    }
  },[appState.isLoggedIn])

  useEffect(() => {
    if(appState.isLoggedIn){
      const fetchUserProfile = async () => {
        const userDoc = doc(firestore, 'users', auth.currentUser.uid);
        const docSnap = await getDoc(userDoc);
        
        if (docSnap.exists()) {
          const details = docSnap.data()
          console.log(details)
          setLoadedUser({
            ...details,
            uid: currentUser?.uid
          });
        } else {
          console.log('No such document!');
        }
      };
      
      const fetchServices = async () => {
        const servicesCollectionRef = collection(firestore, 'services');
        const querySnapshot = await getDocs(servicesCollectionRef);
        const servicesArray = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
  
        setAppState(prev => ({
          ...prev,
          services: servicesArray
        }));
      };  

      fetchServices()
      fetchUserProfile();
    } else{
      setLoadedUser({
        username: "",
        uid: "",
        displayName: "",
        email: "",
        profileImageUrl: "",
        pronouns: "",
        dateOfBirth: "",
        sex: "",
        isProvider: false,
        myServices: [],
        friends: [],
        messages: []
      })
    }

    setShowMenu(false)

  }, [appState.isLoggedIn, currentPath, currentUser]);

  const [deviceType, setDeviceType] = useState('PC');

  const [isIOS, setIsIOS] = useState(false)

  useEffect(() => {
    const detectDeviceType = () => {
      const userAgent = navigator.userAgent;

      const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

      setDeviceType(isMobile ? 'Mobile' : 'PC');
    };

    detectDeviceType();
  }, []);

  useEffect(() => {
    const detectOperatingSystem = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      const iOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
      const Android = /Android/.test(userAgent);

      if (iOS) {
        setIsIOS(true);
      } else if (Android) {
        setIsIOS(false);
      }
    };

    detectOperatingSystem();
  }, []);

  return (
    <>
      {deviceType === "PC" && <AnimatedCursor
        innerSize={10}
        outerSize={80}
        color='255, 255, 255'
        outerAlpha={0.2}
        innerScale={0.7}
        outerScale={1.5}
        clickables={[
          'a',
          'input[type="text"]',
          'input[type="email"]',
          'input[type="number"]',
          'input[type="submit"]',
          'input[type="image"]',
          'label[for]',
          'select',
          'textarea',
          'button',
          '.button',
          '.btn',
          '.link'
        ]}
      />}

      <Header showMenu={showMenu} setShowMenu={setShowMenu} links={appState.links} appState={appState} setAppState={setAppState} />

      <Menu showMenu={showMenu} setShowMenu={setShowMenu} links={appState.links} appState={appState} setAppState={setAppState} />

      {appState.isLoading && <Preloader />}
      
      <div className={`app ${appState.isLoading ? "isLoading" : ""}`}>
        <Routes>
          
          <Route path="/home" element={<Home loadedUser={loadedUser} appState={appState} />} />
          
          <Route path="/login" element={<Login appState={appState} setAppState={setAppState} />} />

          <Route path="/download" isIOS={isIOS} element={<Download appState={appState} setAppState={setAppState} />} />
          
          <Route path="/admin" element={appState.isLoggedIn ? (loadedUser.isAdmin ? <Admin /> : <RestrictedPage reason={"This page is Restricted to Admins Only"} title={"401"} action={"/"} actionTitle={"Home"} />) : <RestrictedPage reason={"Create an account or Sign in to View this Page"} title={"401"} action={"/login"} actionTitle={"Sign In"} />} />

          <Route path="/profile" element={appState.isLoggedIn ? <ProfilePage userProfile={loadedUser} /> : <RestrictedPage reason={"Create an account or Sign in to View this Page"} title={"401"} action={"/login"} actionTitle={"Sign In"} />} />
          
          <Route path="/provider/:profileId" element={appState.isLoggedIn ? <Profile appState={appState} setAppState={setAppState} userProfile={loadedUser} /> : <RestrictedPage reason={"Create an account or Sign in to View Profiles"} title={"401"} action={"/login"} actionTitle={"Sign In"} />} />
          
          <Route path="/update-profile" element={appState.isLoggedIn ? <UpdateProfilePage appState={appState} setAppState={setAppState} loadedUser={loadedUser} /> : <RestrictedPage reason={"Create an account or Sign in to View this Page"} title={"401"} action={"/login"} actionTitle={"Sign In"} />} />

          <Route path="/" element={appState.isLoggedIn ? <ProfilePage userProfile={loadedUser} /> : <Home />} />
          
          <Route path="*" element={<NotFoundPage />} />
        
        </Routes>
      </div>
    </>
  );
}

export default App;
