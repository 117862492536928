import React from 'react'
import { FaInstagram, FaRegEnvelope, FaTwitter } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { useAuth } from '../context/AuthContext';

const Footer = ({links, loadedUser, appState, socialLinks}) => {
    const { currentUser } = useAuth();
  return (
    <>
        <footer className='footer'>
            <div className='content'>
                <div className='top'>
                    <Link className='logo' to={"/home"} >
                        <div className='logo-el'></div>
                    </Link>
                    <div className='links-holder'>
                        <Link to={"/home"}>
                            Home
                        </Link>
                        {currentUser ? <>
                            <Link to={"/services/escorts"}>
                                Profile
                            </Link>
                        </> : <>
                            <Link to={"/login"}>
                                Login
                            </Link>
                            <Link to={"/signup"}>
                                Signup
                            </Link>
                        </>}
                        <Link target="_blank" to={"https://linktr.ee/massageandx"}>
                            Contact
                        </Link>
                    </div>
                    <div className='social-links'>
                        <a href='#!' target="_blank" rel='noreferrer'>
                            <FaInstagram />
                        </a>
                        <a href='#!' target="_blank" rel='noreferrer'>
                            <FaTwitter />
                        </a>
                        <a href='#!' target="_blank" rel='noreferrer'>
                            <FaRegEnvelope />
                        </a>
                    </div>
                </div>
                <div className='bottom'>
                    © 2024 MyCrushdate.com
                </div>
            </div>
        </footer>
    </>
  )
}

export default Footer