// src/pages/NotFoundPage.js
import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
  return (
    <div className='page not-found'>
      <div className='content'>
        <h1>
          <span>404</span>
        </h1>
        <p style={{color: "#fff"}}>Sorry, the page you are looking for does not exist.</p>
        <Link to="/" replace={true} className='btn outline white'>Go Home</Link>
      </div>
    </div>
  );
};

export default NotFoundPage;