import React from 'react'
import { HashLoader } from 'react-spinners'

const Preloader = ({type}) => {
  return (
    <div className='preloader'>
        <HashLoader color="#ffffff" />
    </div>
  )
}

export default Preloader