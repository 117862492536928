import React from 'react'
import { Link } from 'react-router-dom'

const RestrictedPage = ({title, reason, action, actionTitle}) => {
  return (
    <div className='page not-found'>
        <div className='content'>
          <h1><span>{title}</span></h1>
          <h3>
              {reason}
          </h3>
          <Link className='btn outline white' to={action} replace={true}>{actionTitle}</Link>
        </div>
    </div>
  )
}

export default RestrictedPage