import { FaArrowRight, FaInfo, FaUserCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";

const ProfilePage = ({userProfile}) => {
  return (
    <div className="page dashboard-page profile">
      <div className="content">
        {(userProfile?.profileImageUrl && userProfile?.firstName && userProfile?.lastName && userProfile?.email && userProfile?.bio && userProfile?.phone && userProfile?.age) && <div className="install-info">
        <div className="info-tag">
          <FaInfo />
        </div>
        <div className="img">
          <img src="https://cdn-icons-png.flaticon.com/128/3322/3322105.png" alt="" />
        </div>
          <p>
            <b>Congrats</b>. Your Profile is Ready. Download our Mobile App for <b>Android/IOS</b> now to meet fantastic matches 🚀
          </p>
          <Link to={"/download"} className="btn outline white">
            Download <FaArrowRight />
          </Link>
        </div>}
        <div className="heading">
          <h1><span>Profile</span></h1>
          {userProfile.profileImageUrl ? <img src={userProfile.profileImageUrl} alt="Profile" /> : <FaUserCircle />}
        </div>
        {userProfile && (
          <div className="profile-details">
            {/* <div className="detail">
              Username
              <p>
                {userProfile.uid}
              </p>
            </div> */}
            <div className="detail">
              User Name
              <p>
                {userProfile.userName}
              </p>
            </div>
            <div className="detail">
              First Name
              <p>
                {userProfile.firstName}
              </p>
            </div>
            <div className="detail">
              Last Name
              <p>
                {userProfile.lastName}
              </p>
            </div>
            <div className="detail">
              Email Address
              <p>
                {userProfile.email}
              </p>
            </div>
            <div className="detail">
              Bio
              <p>
                {userProfile.bio}
              </p>
            </div>
            <div className="detail">
              Gender
              <p style={{
                width: "100px"
              }}>
                {userProfile?.gender}
              </p>
            </div>
            <div className="detail">
              Looking For
              <p>
                {userProfile?.lookingFor}
              </p>
            </div>
            <div className="detail" style={{width: "fit-content"}}>
              Age
              <p>
                {userProfile.age}
              </p>
            </div>
            <div className="detail">
              Country
              <p>
                {userProfile.selectedCountry?.label}
              </p>
            </div>
            <div className="detail">
              State
              <p>
                {userProfile.selectedState?.label}
              </p>
            </div>
            <div className="detail">
              City
              <p>
                {userProfile.selectedCity?.label}
              </p>
            </div>
            <div className="detail" style={{
              width: "fit-content"
            }}>
              Website
              <p>
                {userProfile.website}
              </p>
            </div>
            <div className="detail" style={{
              width: "fit-content"
            }}>
              Phone
              <p>
                {userProfile.phone}
              </p>
            </div>
          </div>
        )}
        <Link to={"/update-profile"} className="btn outline white">
          Update Profile
        </Link>
        <Link to={"/dashboard/become-a-provider"} className="btn outline small" style={{
          marginTop: "10px",
          fontSize: "10px"
        }}>
          Become a Provider
        </Link>
        <Footer />
      </div>
    </div>
  );
};

export default ProfilePage;
