import React from 'react'
import checkImg from "../assets/check.png"

const SuccessCard = ({title, message, action}) => {
  return (
    <>
        <div className='success-card'>
            <h1>
                {title || "Successful"}
            </h1>
            <img src={checkImg} alt='' style={{
                marginBottom: "20px"
            }} />
            {action && <button className='btn outline white' onClick={()=>{
                action.onClick()
            }}>{action.title}</button>}
        </div>
    </>
  )
}

export default SuccessCard