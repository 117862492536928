import React from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { auth } from '../firebaseConfig';
import { signOut } from 'firebase/auth';

const Menu = ({links, showMenu, setShowMenu, appState, setAppState}) => {
    const navigate = useNavigate()

    const handleLogout = async () => {
        setAppState((prev)=>{
            return ({
                ...prev,
                isLoading: true
            })
        })
        const confirm = window.confirm("Are you sure you want to Logout?")
        if(confirm){
            try {
                await signOut(auth);
                setAppState((prev)=>{
                    return ({
                        ...prev,
                        isLoggedIn: false,
                        isLoading: false,
                    })
                })
                navigate("/")
            } catch (error) {
                setAppState((prev)=>{
                    return ({
                        ...prev,
                        isLoading: false
                    })
                })
                console.error('Error signing out:', error);
            }
        } else{
            setAppState((prev)=>{
                return ({
                    ...prev,
                    isLoading: false
                })
            })
        }
    };

    return (
    <>
        <menu className={`menu ${showMenu ? "show" : ""}`}>
            <div className='content'>
            {links && <div className='links-holder'>
                    {
                        links.map((link, index)=>{
                            return (
                                <NavLink className={`header-link ${link.isBig ? "big" : ""} ${link.scrollToSection ? "default" : ""}`} key={index}  to={link.scrollToSection ? "/" : link.to} onClick={()=>{
                                    setShowMenu(false)
                                    if(link.scrollToSection){
                                        appState.functions?.scrollToSection(link.to)
                                    }
                                }} style={{
                                    transitionDelay: showMenu ? `0.${index + 2}s` : "0s",
                                }} >
                                    <p>{link.title}</p>
                                </NavLink>
                            )
                        })
                    }
                    {appState?.isLoggedIn && <>
                        <Link onClick={()=>{
                            setShowMenu(false)
                            setAppState((prev)=>{
                                return ({
                                    ...prev,
                                    isLoading: true
                                })
                            })
                            setTimeout(()=>{
                                handleLogout()
                            }, 200)
                        }} style={{
                            transitionDelay: showMenu ? `0.${(links?.length ? links.length : 0) + 2}s` : "0s",
                        }}>Logout</Link>
                    </>}
                </div>}
            </div>
        </menu>
    </>
  )
}

export default Menu