import React from 'react'
import { FaAppStore, FaArrowRight, FaDownload } from 'react-icons/fa'
// import Footer from '../components/Footer'

const Download = ({isIOS}) => {
  return (
    <div className='page dashboard-page download-page'>
        <div className='content'>
            <div className='heading'>
                <h1><span>Download</span></h1>
                {isIOS ? <FaAppStore /> : <FaDownload style={{
                    padding: "10px",
                }} />}
            </div>
            <div className='info'>
                <p>Install our App on your <b>IOS/Android</b> device and get access to full features</p>
            </div>
            <div className='features-holder'>
                <div className='feature'>
                    <p>
                        Real Time Chat
                    </p>
                </div>
                <div className='feature'>
                    <p>
                        Real Time Chat
                    </p>
                </div>
                <div className='feature'>
                    <p>
                        Real Time Chat
                    </p>
                </div>
                <div className='feature'>
                    <p>
                        Real Time Chat
                    </p>
                </div>
                <div className='feature'>
                    <p>
                        Real Time Chat
                    </p>
                </div>
            </div>
            <div className='btn-holder'>
                <button className='btn animated'>
                    Install <FaArrowRight />
                </button>
            </div>
        </div>
    </div>
  )
}

export default Download