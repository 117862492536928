import React, { useEffect, useState } from 'react'
import { FaDiscord, FaFacebookF, FaGlobe, FaInstagram, FaLocationArrow, FaTwitter, FaUserCircle } from 'react-icons/fa'
import { IoIosFemale, IoIosMale } from 'react-icons/io'
import ServiceCard from '../components/ServiceCard'
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../firebaseConfig';

const Profile = ({setAppState, userProfile}) => {
  const { profileId } = useParams();
  console.log(profileId)

  const [profileDetails, setProfileDetails] = useState({
    displayName: "",
    profileImageUrl: "",
    userName: "",
    uid: "",
    age: "",
    city: "",
    bio: "",
    sex: "",
    gallery: [],
    services: [],
    links: {
      instagram: "",
      facebook: "",
      twitter: "",
      discord: "",
      website: "",
    },
  })
  
  const navigate = useNavigate()
  
  useEffect(()=>{
    function calculateAge(birthDateString) {
      const birthDate = new Date(birthDateString);
      const today = new Date();
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
    
      return age;
    }

    const fetchUserProfile = async () => {
      const userDoc = doc(firestore, 'users', profileId);
      const docSnap = await getDoc(userDoc);
      
      if (docSnap.exists()) {
        const details = docSnap.data()
        const {bio, displayName, dateOfBirth, email, isProvider, profileImageUrl, pronouns, sex, userName} = details
        const age = calculateAge(dateOfBirth)
        setProfileDetails((prev)=>{
          return ({
            ...prev,
            displayName,
            userName,
            sex,
            age,
            uid: profileId,
            city: "",
            profileImageUrl,
            bio,
            email,
            pronouns,
            isProvider
          })
        })
      } else {
        console.log('No such document!');
      }
    };
    if(profileId === userProfile?.uid){
      navigate("/profile", {replace: true})
    }

    fetchUserProfile()

  }, [profileId, navigate, userProfile])

  return (
    <div className='page user-profile'>
        <div className='content'>
          <div className='img-holder'></div>
          {profileDetails?.profileImageUrl ? <div className='profile-img' style={{
            backgroundImage: `url(${profileDetails?.profileImageUrl})`
          }}></div> : <FaUserCircle style={{
            width: "150px",
            height: "150px",
            color: "#666",
          }} />}
          <div className='details'>
            <h1>
              {profileDetails?.sex === "male" && <IoIosMale />}
              {profileDetails?.sex === "femmale" && <IoIosFemale />}
              {((profileDetails?.sex !== "male") && (profileDetails?.sex !== "female")) && <b>🌈</b>} <span>{profileDetails?.displayName || profileDetails?.userName || "..."}</span>
            </h1>
            <div className='btwn'>
              <p className='age'>
                <b>{profileDetails.age || "Not specified"}</b> years
              </p>
              <p className='city'>
                <FaLocationArrow /> {profileDetails?.city || "Not Specified"}
              </p>
            </div>
            <div className='bio'>
              <label>
                Bio
              </label>
              <p>
                {profileDetails?.bio || "Not specified"}
              </p>
            </div>
          </div>
          <div className='gallery'>
            <label>
              Gallery
            </label>
            <div className='scroller'>
              <div className='photo'></div>
              <div className='photo'></div>
              <div className='photo'></div>
              <div className='photo'></div>
            </div>
          </div>
          <div className='services'>
            <label>Services</label>
            <div className='items-holder'>
              <ServiceCard isTemplate={true} />
              <ServiceCard isTemplate={true} />
              <ServiceCard isTemplate={true} />
            </div>
          </div>
          {((profileDetails?.links?.instagram) || (profileDetails?.links?.facebook) || (profileDetails?.links?.twitter) || (profileDetails?.links?.discord) || (profileDetails?.links?.website)) && <div className='contact'>
            <label>
              Contact
            </label>
            <div className='links'>
              {profileDetails?.links?.instagram && <a href='#!' target="_blank" rel='noreferrer'>
                <FaInstagram />
              </a>}
              {profileDetails?.links?.facebook && <a href='#!' target="_blank" rel='noreferrer'>
                <FaFacebookF />
              </a>}
              {profileDetails?.links?.twitter && <a href='#!' target="_blank" rel='noreferrer'>
                <FaTwitter />
              </a>}
              {profileDetails?.links?.discord && <a href='#!' target="_blank" rel='noreferrer'>
                <FaDiscord />
              </a>}
              {profileDetails?.links?.website && <a href='#!' target="_blank" rel='noreferrer'>
                <FaGlobe />
              </a>}
            </div>
          </div>}
        </div>
    </div>
  )
}

export default Profile