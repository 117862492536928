import React, { useEffect, useState } from 'react';
import { auth, firestore, storage } from '../firebaseConfig'; // Adjust this import according to your project structure
import { doc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import Select from 'react-select';
import { Country, State, City } from 'country-state-city'
import { FaArrowRight } from 'react-icons/fa';

const UpdateProfilePage = ({appState, setAppState, loadedUser}) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [bio, setBio] = useState('');
  const [sex, setSex] = useState('');
  const [age, setage] = useState('');
  const [profileImage, setProfileImage] = useState(null);
  const [error, setError] = useState(null)
  const [website, setWebsite] = useState("")
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [selectedState, setSelectedState] = useState(null)
  const [selectedCity, setSelectedCity] = useState(null)
  const navigate = useNavigate()
  const [countries, setCountries] = useState([]);
  const [phone, setPhone] = useState("");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [hasSet, setHasSet] = useState(false)
  const [imgSrc, setImgSrc] = useState(false)

  const handleImageChange = (e) => {
    const file = e.target.files[0]
    setProfileImage(file);
    const reader = new FileReader();
    reader.onload = function(e) {
      const imgSrc = e.target.result;
      setImgSrc(imgSrc)
    };
    reader.readAsDataURL(file);
  };

  useEffect(()=>{
    if(loadedUser){
        setFirstName(loadedUser.firstName)
        setLastName(loadedUser.lastName)
        setBio(loadedUser.bio)
        setSex(loadedUser.sex)
        setage(loadedUser.age)
        setWebsite(loadedUser.website)
        setSelectedCountry(loadedUser.selectedCountry)
        setSelectedState(loadedUser.selectedState)
        setSelectedCity(loadedUser.selectedCity)
        setHasSet(true)
    }
  },[loadedUser])

  useEffect(() => {
    const loadCountries = () => {
      setCountries(Country.getAllCountries().map(({ isoCode, name }) => ({ value: isoCode, label: name })));
    };
    loadCountries();
  }, []);

  useEffect(() => {
    if (selectedCountry ) {
      setStates(State.getStatesOfCountry(selectedCountry?.value).map(({ isoCode, name }) => ({ value: isoCode, label: name })));
      if(hasSet){
        setSelectedState(null); // Reset state selection when country changes
      }
    }
  }, [selectedCountry, hasSet]);
  
  useEffect(() => {
    if (selectedState) {
      setCities(City.getCitiesOfState(selectedCountry.value, selectedState.value).map(({ name }) => ({ value: name, label: name })));
      setSelectedCity(null); // Reset city selection when state changes
      if(hasSet){
        setSelectedCity(null); // Reset state selection when country changes
      }
    }
  }, [selectedState, selectedCountry, hasSet]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null)
    if(selectedCountry && age && firstName && lastName && bio && phone && selectedState){
      try{
          setAppState((prev)=>{
              return(
                  {
                      ...prev,
                      isLoading: true,
                  }
              )
          })
          let profileImageUrl = '';
          if (profileImage) {
            const imageRef = ref(storage, `profileImages/${auth.currentUser.uid}`);
            await uploadBytes(imageRef, profileImage);
            profileImageUrl = await getDownloadURL(imageRef);
          }
      
          const userDoc = doc(firestore, 'users', auth.currentUser.uid);
          await updateDoc(userDoc, {
              firstName,
              lastName,
              bio,
              // gender,
              age,
              website,
              selectedCity,
              phone,
              selectedCountry,
              selectedState,
              ...(profileImageUrl && { profileImageUrl })
          });
          setAppState((prev)=>{
              return(
                  {
                      ...prev,
                      isLoading: false,
                  }
              )
          })
          
          navigate("/profile")
      } catch(error){
          setAppState((prev)=>{
              return(
                  {
                      ...prev,
                      isLoading: false,
                  }
              )
          })
          setError(error.message)
          console.error(error)
      }
    } else{
      alert("Please FIll all the Necessary fields")
    }
    
  };

  return (
    <div className='page dashboard-page profile'>
      <div className='content'>
        <div className='heading'>
          <h1><span>Update Profile</span></h1>
        </div>
        <div className='update-holder'>
          {error && <p className='error'>{error}</p>}
          <form onSubmit={handleSubmit}>
          <div>
              <label>Profile Image</label>
              <div style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginBottom: "10px",
              gap: "10px",
              color: "#666"
              }}>
                {loadedUser?.profileImageUrl && <>
                  <img  width={70} height={70} style={{
                      borderRadius: "10px",
                      objectFit: "cover",
                      objectPosition: "center",
                    }} src={loadedUser?.profileImageUrl} alt='' />
                  <FaArrowRight />
                </>}
                {profileImage && <>
                  <img width={70} height={70} style={{
                    borderRadius: "10px",
                    objectFit: "cover",
                    objectPosition: "center",
                    background: "#0003"
                  }} src={imgSrc} alt='' />
                </> }
              </div>
              <input type="file" onChange={handleImageChange} accept="image/*" />
            </div>
            
            <div>
              <label>First Name*</label>
              <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder="First Name" />
            </div>

            <div>
              <label>Last Name*</label>
              <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder="Last Name" />
            </div>
            
            <div>
              <label>
                Bio*
              </label>
              <textarea value={bio} onChange={(e) => setBio(e.target.value)} placeholder="Bio"></textarea>
            </div>
            
            {/* <div>
              <label>Gender</label>
              <div className='holder' style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "5px",
              }}>
                <span className={`btn ${sex?.toLowerCase()===("male") ? "filled" : "outline"}`} onClick={()=>{
                  setSex("male")
                }}>
                  
                </span>
                <span  className={`btn ${sex?.toLowerCase()===("female") ? "filled" : "outline"}`}  onClick={()=>{
                  setSex("female")
                }}>
                  <IoIosFemale />
                </span>
              </div>
            </div> */}

            <div>
              <label htmlFor="password">Country*</label>
              <div className='select-holder'>
                  <Select
                      options={countries}
                      value={selectedCountry}
                      onChange={setSelectedCountry}
                      placeholder="Select Country"
                  />
              </div>
          </div>
          <div>
              <label htmlFor="state">State*</label>
              <div className='select-holder'>
                  <Select
                  sex={sex}
                      options={states}
                      value={selectedState}
                      onChange={setSelectedState}
                      placeholder="Select State"
                      isDisabled={!selectedCountry}
                  />
              </div>
          </div>
          <div>
              <label htmlFor="city">City*</label>
              <div className='select-holder'>
                  <Select
                      options={cities}
                      value={selectedCity}
                      onChange={setSelectedCity}
                      placeholder="Select City"
                      isDisabled={!selectedState}
                  />
              </div>
          </div>
            
            <div>
              <label>
                Website (optional)
              </label>
              <input type="url" value={website} onChange={(e) => setWebsite(e.target.value)} placeholder="Website" />
            </div>
            
            <div>
              <label>
                Age*
              </label>
              <input type="number" min={18} value={age} onChange={(e) => setage(e.target.value)} placeholder="18+" required />
            </div>

            <div>
              <label>
                Phone (whatsapp enabled)*
              </label>
              <input type="tel" value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="+1 234 XXX XXXX" required />
            </div>
            
            <button className='btn white outline' type="submit" style={{
              marginBottom: "30px"
            }}>Update Profile</button>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default UpdateProfilePage;
