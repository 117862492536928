import React from 'react'
import { FaArrowRight, FaEllipsisV, FaLocationArrow } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const ServiceCard = ({service, isTemplate}) => {
  return (
    <>
        <div className='service'>
            <div className='provider-img' style={{
                backgroundImage: `url(${service?.provider?.profilePhoto})`
            }}></div>
            <div className='provider-details'>
            <p className='provider-display-name'>
                {service?.provider?.displayName}
            </p>
            <p className='service-type'>
                {service?.type}, <span><FaLocationArrow /> {service?.location}</span> 
            </p>
            </div>
            <div className='charge'>
            <b>${service?.charge}</b>/{service?.rate === "hour" ? "hr" : service?.rate}
            </div>
            {!isTemplate && <div className='options'>
            <FaEllipsisV />
            <div className='options-holder'>
                <Link to={`/provider/${service?.provider?.uid}`}>
                Profile <FaArrowRight size={10} />
                </Link>
                <a href="mailto:support@massageandx.com" target="_blank" rel="noreferrer">
                Report
                </a>
            </div>
            </div>}
        </div>
    </>
  )
}

export default ServiceCard