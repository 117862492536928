import React, { useState } from 'react'
import { FaChevronDown } from 'react-icons/fa'

const Side = ({details, changing, userDetails, setUserDetails, data}) => {
    const value = userDetails?.[changing]
    const [showSelectors, setShowSelectors] = useState(false)
    return (
    <>
        <div className='side'>
          <p>{details}</p>
          <div className='select' onClick={()=>{
            setShowSelectors(!showSelectors)
          }}>
            <p>{value}</p> <FaChevronDown style={{
                transition: "all 0.5s ease",
                transform: `rotate(${showSelectors ? "180" : "0"}deg)`,
            }} />
          </div>
          <div className={`selector ${showSelectors ? "active" : ""}`}>
            {
              data.map((genderx, index)=>{
                return (
                  <div className='select' key={index} onClick={()=>{
                    try{
                        setShowSelectors(false)
                        setUserDetails({
                            [changing]: genderx
                        })
                    } catch(error){
                        console.error(error)
                    }
                  }}>
                    {genderx}
                  </div>
                )
              })
            }
          </div>
        </div>
    </>
  )
}

export default Side