import React, { useEffect, useState } from 'react'
import Footer from '../components/Footer'
import { FaAngleDoubleRight, FaChevronDown, FaFacebook, FaInfo, FaInstagram, FaTwitter } from 'react-icons/fa'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Side from '../components/Side';
import Select from 'react-select';
import { Country, State, City } from 'country-state-city'
import { Link, useNavigate } from 'react-router-dom';
import SuccessCard from '../components/SuccessCard';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, firestore } from '../firebaseConfig';
// import { useAuth } from '../context/AuthContext';
import { doc, setDoc } from 'firebase/firestore';

// import required modules

const Home = ({loadedUser, appState}) => {
  const sections = [
    {
      title: "Hero",
      sectionId: "section-i"
    },
    {
      title: "About",
      sectionId: "section-ii"
    },
    {
      title: "More",
      sectionId: "section-iii"
    },
  ]

  const [ currentSection, setCurrentSection ] = useState({
    ...sections[0]
  })

  const [ showSideBar, setShowSideBar ] = useState(false)
  const [ sideBarScaleOn, setSideBarScaleOn ] = useState(false)
  const [hasSet, setHasSet] = useState(false)
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [selectedState, setSelectedState] = useState(null)
  const [selectedCity, setSelectedCity] = useState(null)

  useEffect(() => {
    const loadCountries = () => {
      setCountries(Country.getAllCountries().map(({ isoCode, name }) => ({ value: isoCode, label: name })));
    };
    loadCountries();
  }, []);

  useEffect(() => {
    if (selectedCountry ) {
      setStates(State.getStatesOfCountry(selectedCountry?.value).map(({ isoCode, name }) => ({ value: isoCode, label: name })));
      if(hasSet){
        setSelectedState(null); // Reset state selection when country changes
      }
    }
  }, [selectedCountry, hasSet]);
  
  useEffect(() => {
    if (selectedState) {
      setCities(City.getCitiesOfState(selectedCountry.value, selectedState.value).map(({ name }) => ({ value: name, label: name })));
      setSelectedCity(null); // Reset city selection when state changes
      if(hasSet){
        setSelectedCity(null); // Reset state selection when country changes
      }
    }
  }, [selectedState, selectedCountry, hasSet]);

  useEffect(()=>{
    setTimeout(()=>{
      setShowSideBar(true)
    }, 500)
  },[])
  
  useEffect(()=>{
    const scrollToSection = (id)=>{
      const element = document.getElementById(id)
      if(element){
        element.scrollIntoView()
      } else{
        console.log("Not found", currentSection?.sectionId)
      }
    }
    setSideBarScaleOn(false)
    setTimeout(()=>{
      scrollToSection(currentSection?.sectionId)
      setShowSideBar(false)
    }, 300)
    setTimeout(()=>{
      setSideBarScaleOn(true)
      setShowSideBar(true)
    }, 700)
  },[currentSection])

  const toPartII = (e)=>{
    e.preventDefault()
    gotoNextSection()
  }

  const data = {
    genders: [
      "Male",
      "Female",
      "Other",
    ],
    ageLimit: 18,
  }

  const [userDetails, setUserDetails] = useState({
    email: "",
    age: "ax",
    gender: "Male",
    lookingFor: "Female",
    country: "Nigeria",
    city: "Lagos",
    userName: "",
    password: "",
  })

  const handleDetails = (data)=>{
    setUserDetails((prev)=>{
      return ({
        ...prev,
        ...data
      })
    })
  }

  const steps = [
    {
      id: "step-i",
      title: "Step 1",
      description: "What are You Looking for",
      index: 0,
      element: <>
        
      </>,
    },
    {
      id: "step-ii",
      title: "Step 2",
      description: "What is Your Age?",
      index: 1,
    },
    {
      id: "step-iii",
      title: "Step 3",
      description: "Where are You",
      index: 2,
    },
    {
      id: "step-iv",
      title: "Step 4",
      description: "Email Address",
      moreInfo: "we'll send updates here",
      index: 3,
    },
    {
      id: "step-v",
      title: "Step 5",
      description: "Pick Your Username and Password",
      btnTitle: "FINALIZE ACCOUNT",
      index: 4,
    },
  ]
  
  const [ currentStep, setCurrentStep ] = useState({...steps[0]})
  const [ hideStep, setHideStep ] = useState(true)

  useEffect(()=>{
    setTimeout(()=>{
      setHideStep(false)
    }, 800)
  },[hideStep])

  const [signedUp, setSignedUp] = useState(false)

  const createUserProfileDocument = async (details, user) => {
    try{
        await setDoc(doc(firestore, "users", user.uid), {
          ...details,
          uid: user.uid,
        });
        return true
    } catch(error){
        console.error(error)
        throw error
    }
};

  const submitDetails = async()=>{
    console.log(userDetails)
    const {email, password} = userDetails
    
    try{
      const result = await createUserWithEmailAndPassword(auth, email, password);
      const user = result.user
      await createUserProfileDocument(userDetails, user);
      setSignedUp(true)
    } catch(error){
      console.error(error)
    }
  }

  const gotoNextSection = ()=>{
    const currentStepDetails = steps?.filter((step)=>{
      return step.id === currentStep?.id
    })
    const currentStepIndex = currentStepDetails[0]?.index
    const nextStep = steps[currentStepIndex + 1]
    if(currentStep?.id === steps[2]?.id){
      if(selectedCountry && selectedState){
        setUserDetails((prev)=>{
          return ({
            ...prev,
            selectedCountry,
            selectedState,
            selectedCity,
          })
        })
        if(nextStep){
          setHideStep(true)
          setTimeout(()=>{
            setCurrentStep({...nextStep})
          }, 500)
        } else{
        }
      } else{
        alert("Please Fill the Required Information")
      }
    } else{
      if(nextStep){
        setHideStep(true)
        setTimeout(()=>{
          setCurrentStep({...nextStep})
        }, 500)
      } else{
        if(currentStep?.id === steps[4].id){
          submitDetails()
        }
      }
    }
  }

  const navigate = useNavigate()

  const completedAction = {
    title: "Continue",
    onClick: ()=>{
      navigate("/dashboard")
    }
  }

  return (
    <>
      {signedUp && <div className='success-backdrop'>
        <SuccessCard title={"Account created successfully"} message={"Click to continue"} action={completedAction} />
      </div>}
      <div className='page home'>
        <div className='content'>
          <section className='hero-section section' id='section-i' style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}>
          {appState?.isLoggedIn && <div className="install-info drop" style={{
            marginTop: "-80px",
            marginBottom: "100px",
          }}>
          <div className="info-tag">
            <FaInfo />
          </div>
            <p>
              You are Already Signed In as <b>{loadedUser?.userName}</b>
            </p>
          </div>}
            <div className='max'>
              <div className='login-link'>Already a member? <Link to={"/login"}>Sign in</Link></div>
              <div className='left'>
                <h1>
                  <span>Crush Dating</span>
                </h1>
                <p className='small'>
                  Create your Profile by Registering Below!
                </p>
                <form onSubmit={toPartII} style={{
                  overflow: "hidden"
                }}>
                  <div className='form-details' style={{
                    // transform: `translateY(${hideStep ? "150" : "0"}%)`,
                    opacity: hideStep ? "0" : "1",
                    transition: "all 0.5s ease",
                  }}>
                    <div className='head'>
                      <div className='steps-select'>
                        {
                          steps?.map((step, index)=>{
                            return (
                              <div className={`step ${currentStep?.index >= index ? "active" : ""}`} onClick={()=>{
                                if(currentStep?.index > index){
                                  setHideStep(true)
                                  setTimeout(()=>{
                                    setCurrentStep({...step})
                                  }, 500)
                                }
                              }}>{index + 1}</div>
                            )
                          })
                        }
                      </div>
                      <h2>
                        <span>{currentStep?.title}</span>:
                      </h2>
                      <p>
                        {currentStep?.description}
                      </p>
                    </div>
                    <div className='step-details'>
                      <div className={`container ${currentStep?.id === steps[4]?.id ? "flex" : ""}`}>
                        {currentStep?.id === steps[0]?.id && <>
                          <Side details="I am a:" value={userDetails.gender} data={data?.genders} changing={"gender"} setUserDetails={(data)=>{
                            handleDetails(data)
                          }} userDetails={userDetails} />
                          <Side details="Looking for:" value={userDetails.lookingFor} data={data?.genders} changing={"lookingFor"} setUserDetails={(data)=>{
                            handleDetails(data)
                          }} userDetails={userDetails} />
                        </>}

                        {currentStep?.id === steps[1]?.id && <>
                          <div className='inp-holder'>
                            <label>
                              My age is:
                            </label>
                            <input placeholder='18+' type='number' max={120} min={18} required value={userDetails?.age} onChange={(e)=>{
                              setUserDetails((prev)=>{
                                return({
                                  ...prev,
                                  age: e?.target?.value
                                })
                              })
                            }} />
                          </div>  
                        </>}

                        {currentStep?.id === steps[2]?.id && <>
                          <div className='inp-holder'>
                            <label>Country*</label>
                            <div className='select-holder'>
                                <Select
                                    options={countries}
                                    value={selectedCountry}
                                    onChange={setSelectedCountry}
                                    placeholder="Select Country"
                                />
                            </div>
                            </div>
                            <div className='inp-holder'>
                                <label>State*</label>
                                <div className='select-holder'>
                                    <Select
                                        options={states}
                                        value={selectedState}
                                        onChange={setSelectedState}
                                        placeholder="Select State"
                                        isDisabled={!selectedCountry}
                                    />
                                </div>
                            </div>
                            <div className='inp-holder'>
                                <label>City</label>
                                <div className='select-holder'>
                                    <Select
                                        options={cities}
                                        value={selectedCity}
                                        onChange={setSelectedCity}
                                        placeholder="Select City"
                                        isDisabled={!selectedState}
                                    />
                                </div>
                            </div>
                        </>}

                        {currentStep?.id === steps[3]?.id && <>
                          <div className='inp-holder'>
                            <label>
                              Email (remains confidential):
                            </label>
                            <input placeholder='email@address.com' type='email' required value={userDetails?.email} onChange={(e)=>{
                              setUserDetails((prev)=>{
                                return({
                                  ...prev,
                                  email: e?.target?.value
                                })
                              })
                            }} />
                          </div>  
                        </>}


                        {currentStep?.id === steps[4]?.id && <div style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "30px"
                        }}>
                          <div className='inp-holder'>
                            <label>
                              Username <small><i>(you cannot change this in the future)</i></small>:
                            </label>
                            <input placeholder='Your Username' type='text' required minLength={5} maxLength={15} value={userDetails?.userName} onChange={(e)=>{
                              setUserDetails((prev)=>{
                                return({
                                  ...prev,
                                  userName: e?.target?.value
                                })
                              })
                            }} />
                          </div>  
                          
                          <div className='inp-holder'>
                            <label>
                              Password:
                            </label>
                            <input placeholder='Your Password' type='password' required minLength={4} maxLength={15} value={userDetails?.password} onChange={(e)=>{
                              setUserDetails((prev)=>{
                                return({
                                  ...prev,
                                  password: e?.target?.value
                                })
                              })
                            }} />
                          </div>  
                        </div>}
                      </div>
                      <button className='btn filled'>
                        {!currentStep?.btnTitle && <>
                        NEXT <FaAngleDoubleRight setHasSet={setHasSet} />
                        </>}
                        {currentStep?.btnTitle && <>
                          {currentStep?.btnTitle}
                        </>}
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              <div className='right'>
                
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </div>
      <div className='footx'>
        <div className='max'>
          <div className='leftx'>
            <p>
              © 2024 mycrushdate.com
            </p>
          </div>
          <div className='rightx'>
            <a href='#!' target="_blank" rel="noreferrer">
              <FaInstagram />
            </a>
            <a href='#!' target="_blank" rel="noreferrer">
              <FaTwitter />
            </a>
            <a href='#!' target="_blank" rel="noreferrer">
              <FaFacebook />
            </a>
          </div>
        </div>
      </div>
      <div className='scroll-down' style={{
        display:"none"
      }}>
        {showSideBar && <>
        <div className='left'>
          {
            sections?.map((section, index)=>{
              return (
                <FaChevronDown key={index} className={(currentSection?.sectionId === section.sectionId) ? "active" : ""} style={{
                  animationDelay: `${1 + (index * 0.5)}s`,
                  scale: sideBarScaleOn ? "1" : "0",
                }} onClick={()=>{
                  setCurrentSection({
                    ...section
                  })
                }} />
              )
            })
          }
        </div>
        <div className='right'>
          {
            sections?.map((section, index)=>{
              return (
                <FaChevronDown key={index} className={(currentSection?.sectionId === section.sectionId) ? "active" : ""} style={{
                  animationDelay: `${1 + (index * 0.5)}s`,
                  scale: sideBarScaleOn ? "1" : "0",
                }} onClick={()=>{
                  setCurrentSection({
                    ...section
                  })
                }} />
              )
            })
          }
        </div>
        </>}
      </div>
    </>
  )
}

export default Home