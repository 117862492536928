import { collection, getDocs } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { FaHandSparkles } from 'react-icons/fa';
import { firestore } from '../firebaseConfig';
import ServiceCard from '../components/ServiceCard';
import ProfileCard from '../components/ProfileCard';

export default function Admin() {
  const [currentScreen, setCurrentScreen] = useState("users")
  const screens = [
    "users",
    "services",
    // "escorts",
    // "massages",
  ]

  const [users, setUsers] = useState([]);
  const [services, setServices] = useState([]);

  useEffect(() => {
    try{
      const fetchUsers = async () => {
        const usersCollectionRef = collection(firestore, 'users');
        const querySnapshot = await getDocs(usersCollectionRef);
        const usersArray = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
  
        setUsers(usersArray);
      };
  
      const fetchServices = async () => {
        const servicesCollectionRef = collection(firestore, 'services');
        const querySnapshot = await getDocs(servicesCollectionRef);
        const servicesArray = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
  
        setServices(servicesArray);
      };
  
      fetchUsers();
      fetchServices()
    } catch(error){
      console.error(error)
    }
  }, []);

  return (
    <div className='page dashboard-page admin'>
      <div className='content'>
      <div className='heading'>
            <h1>
              <span>Admin</span>
            </h1>
            <>
                <FaHandSparkles style={{
                  padding: "10px"
                }} />
            </>
          </div>
      </div>
      <div className='barx'>
        {screens?.map((screen, index)=>{
          return (
            <button key={index} className={`btn ${currentScreen === screen ? "filled" : "outline"} small`} onClick={()=>{
              setCurrentScreen(screen)
            }}>
              {screen}
            </button>
          )
        })}
      </div>
      <div className='data-holder'>
        {currentScreen === "users" && <>
          {
            users.map((user, index)=>{
              return (
                <ProfileCard key={index} user={user} />
              )
            })
          }
        </>}
        {currentScreen === "services" && <>
                {services.map((service, index)=>{
                  return (
                    <ServiceCard key={index} service={service} />
                  )
                })
                }
        </>}
      </div>
    </div>
  );
}