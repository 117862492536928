import React from 'react'
import { FaChevronDown, FaPowerOff } from 'react-icons/fa'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { auth } from '../firebaseConfig';
import { signOut } from 'firebase/auth';
import logoImg from "../assets/logo.png"

const Header = ({links, appState, setAppState, showMenu, setShowMenu}) => {
    const navigate = useNavigate()

    const handleLogout = async () => {
        setAppState((prev)=>{
            return ({
                ...prev,
                isLoading: true
            })
        })
        const confirm = window.confirm("Are you sure you want to Logout?")
        if(confirm){
            try {
                await signOut(auth);
                setAppState((prev)=>{
                    return ({
                        ...prev,
                        isLoggedIn: false,
                        isLoading: false,
                    })
                })
                navigate("/")
            } catch (error) {
                setAppState((prev)=>{
                    return ({
                        ...prev,
                        isLoading: false
                    })
                })
                console.error('Error signing out:', error);
            }
        } else{
            setAppState((prev)=>{
                return ({
                    ...prev,
                    isLoading: false
                })
            })
        }
    };

    return (
    <>
        <header className={`header ${showMenu ? "show" : ""}`}>
            <div className='content'>
                <Link to={"/home"} className='logo-holder'>
                    <div className='logo-el'>
                        <img src={logoImg} alt='' />
                    </div>
                </Link>
                {links && <div className='links-holder'>
                    {
                        links.map((link, index)=>{
                            return (
                                <NavLink className={`header-link ${link.isBig ? "big" : ""} ${link.scrollToSection ? "default" : ""}`} key={index} to={link.scrollToSection ? "/" : link.to} onClick={()=>{
                                    if(link.scrollToSection){
                                        appState.functions?.scrollToSection(link.to)
                                    }
                                }}>
                                    <p>{link.title}</p>
                                </NavLink>
                            )
                        })
                    }
                </div>}
                {appState?.isLoggedIn && <div className='account-holder'>
                    <span>
                        <p>
                            Account
                        </p>
                        <FaChevronDown />
                    </span>
                    <div className='actions'>
                        <Link className='btn' to={"/profile"}>Profile</Link>
                        <button className='btn' onClick={()=>{
                            setAppState((prev)=>{
                                return ({
                                    ...prev,
                                    isLoading: true
                                })
                            })
                            setTimeout(()=>{
                                handleLogout()
                            }, 200)
                        }}>Logout <FaPowerOff /></button>
                    </div>
                </div>}
                <div className={`hamburger ${showMenu ? "show" : ""}`} onClick={()=>{
                    setShowMenu(!showMenu)
                }}>
                    <div className='bar i'></div>
                    <div className='bar ii'></div>
                </div>
            </div>
        </header>
    </>
  )
}

export default Header