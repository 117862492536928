import React from 'react'
import { FaLocationArrow } from 'react-icons/fa'
import { IoIosFemale, IoIosMale } from 'react-icons/io'
import userImg from "../assets/user.png"

const ProfileCard = ({user, isTemplate}) => {
    const displayName = `${user.firstName} ${user.lastName}`
    const location = `${user.country.value}, ${user.state.label}`
    console.log(user)
    return (
    <>
        <div className='user'>
            <div className='provider-img' style={{
                backgroundImage: `url(${user?.profilePhoto ? user?.profilePhoto : userImg})`,
                backgroundSize: user?.profilePhoto ? "cover" : "30px",
                backgroundRepeat: "no-repeat",
            }}></div>
            <div className='provider-details'>
            <p className='provider-display-name'>
                {displayName}
            </p>
            <p className='user-type'>
                <span><FaLocationArrow /> {location}</span> 
            </p>
            </div>
            {user?.sex && <div className='charge'>
            <span className='btn filled small'>
                {user?.sex === "male" ? <IoIosMale /> : <IoIosFemale />}
            </span>
            </div>}
        </div>
    </>
  )
}

export default ProfileCard