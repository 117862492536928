import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import SuccessCard from '../components/SuccessCard';

export default function Login({appState, setAppState}) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { currentUser } = useAuth();
    const [error, setError] = useState(null)
    const navigate = useNavigate();

    const [signedIn, setSignedIn] = useState(false)

    const handleSubmit = async(e)=>{
        e.preventDefault()
        console.log(email, password)
        setSignedIn(true)
    }

    const completedAction = {
        title: "Continue",
        onClick: ()=>{
          navigate("/dashboard")
        }
    }

    return (
        <>
            {signedIn && <div className='success-backdrop'>
                <SuccessCard setError={setError} title={"Signin successful"} message={"Click to continue"} action={completedAction} />
            </div>}
            <div className='page auth login-page'>
                <div className='content'>
                    <h1><span>Login</span></h1>
                    {error && <p className='error'>{error}</p>}
                    <div className='right'>
                        <form onSubmit={handleSubmit}>
                            <div>
                                <label htmlFor="email">Email:</label>
                                <input
                                    type="email"
                                    id="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="password">Password:</label>
                                <input
                                    type="password"
                                    id="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </div>
                            <button className='btn' type="submit">Login</button>
                        </form>
                        {appState.isLoggedIn && <p>
                            You're already signed in as <Link to={"/"}><span>{currentUser?.email}</span></Link>
                        </p>}
                        <div className='actions'>
                            <Link style={{
                                color: "#fff",
                                fontSize: "14px"
                            }} to={"/"} className='btn white'>Create an Account</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}